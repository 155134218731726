import './About.css';
import '../App.css';
import { useEffect, useState, useRef } from 'react';
import { FaArrowUp } from "react-icons/fa";
import { AnimatePresence, motion } from 'framer-motion';

export function About({ darkMode }) {
    const [showScrollButton, setShowScrollButton] = useState(false);
    const aboutRef = useRef(null); 

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    // Animation logic removed for h1 and p
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.1,
            }
        );
    
        const currentAbout = aboutRef.current;
    
        if (currentAbout) {
            observer.observe(currentAbout);
        }
    
        const checkScrollPosition = () => {
            const about = document.querySelector('.about');
            if (!about) return;
    
            const position = about.getBoundingClientRect().top + window.scrollY;
            if (window.scrollY > position) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };
    
        window.addEventListener('scroll', checkScrollPosition);
    
        return () => {
            if (currentAbout) {
                observer.unobserve(currentAbout);
            }
            window.removeEventListener('scroll', checkScrollPosition);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className="about-wrapper">
            <div className="about" ref={aboutRef}>
                <h1>A propos de moi</h1>
                <div>
                    <h2>Passionné par les technologies modernes, je suis motivé à contribuer à des projets innovants et à acquérir une expertise avancée dans la protection des systèmes d’information.
                    Mon objectif est d'acquérir des compétences techniques avancées tout en contribuant activement à des projets concrets au sein d'une entreprise innovante.
                    </h2>
                </div>
                <AnimatePresence>
                    {showScrollButton && (
                        <motion.button
                            className='scrollTop-Btn'
                            onClick={scrollToTop}
                            initial={{ x: '10vw' }}
                            whileHover={{ backgroundColor: 'black', cursor: 'pointer' }} 
                            animate={{ x: 0 }}
                            exit={{ x: '10vw' }}
                            transition={{ duration: 0.5, ease: 'easeInOut' }}
                            style={{ position: 'fixed', bottom: '30px', right: '20px', zIndex: '999', backgroundColor: '#16577d'}}>
                            <FaArrowUp style={{ color: 'white' }} />
                        </motion.button>
                    )}
                </AnimatePresence>
            </div>
        </div>
    )
}