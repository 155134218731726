import './Experiences.css';
import { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';

export function Experiences({ darkMode }) {

    const [animateExperiences, setAnimateExperiences] = useState(false);
    const experiencesRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setAnimateExperiences(true);
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        const currentRef = experiencesRef.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    const experiencesVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    return (
        <div className="experienceswrapper">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path className='svg-skills' fill={darkMode ? '#eee' : '#1d1d1d'} fillOpacity="1" d="M0,0L120,53.3C240,107,480,213,720,218.7C960,224,1200,128,1320,80L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
            <h1>Expériences professionnelles</h1>
            <div className="experiencescontainer" ref={experiencesRef}>
                <motion.div className="experience" initial="hidden"
                    animate={animateExperiences ? "visible" : "hidden"}
                    variants={experiencesVariants}
                    transition={{ duration: 1, delay: 0.5 }}>
                    <h2>Développeur WEB chez Superlative Apps</h2>
                    <motion.div className="postInfo">
                        <div className="years">Janvier 2023 - Décembre 2023</div>
                        <div className="location">Marseille 13ème</div>
                        <div className="post-title">
                            <p>🚀 Alternance chez Superlative Apps
                                Start-up dynamique basée à Marseille, spécialisée dans la création de logiciels et d'applications mobiles.</p>

                            <p>🌟 Projet phare : Chooz'it
                                Application innovante permettant aux utilisateurs de sélectionner, organiser et comparer des produits en ligne.</p>

                            <p>🔧 Installation & Intégration: Mise en place du projet en local et intégration du premier site web à l’extension Chooz'it.</p>

                            <p>🖼️ Développement d'une galerie: Création d'une galerie d'images sous forme de grille pour une visualisation rapide.</p>

                            <p>💬 Pop-up interactive: Développement d'une pop-up informant les utilisateurs de l'intégration du site web consulté.</p>

                            <p>⭐ Système de notation: Conception d’un système pour évaluer la fiabilité des annonces ajoutées par les utilisateurs.</p>

                            <p>✅ Tests & vérifications: Réalisation de tests scriptés et manuels sur une sélection de sites web.</p>

                            <p> Stack technique: JavaScript, React.js, Laravel 8</p>
                        </div>
                    </motion.div>
                </motion.div>
                <motion.div className="experience" initial="hidden"
                    animate={animateExperiences ? "visible" : "hidden"}
                    variants={experiencesVariants}
                    transition={{ duration: 1, delay: 0.5 }}>
                    <h2>Assistant de communication chez la courtoise ressourcerie</h2>
                    <div className="postInfo">
                        <div className="years">Avril 2022 - Octobre 2022</div>
                        <div className="location">Saint maximin la sainte baume</div>
                        <div className="post-title">
                            <p>🌟 Création de fonctionnalités web pour une association</p>
                            <p>💼 Interface "Carrières" : Développement d'une page dédiée permettant aux visiteurs de consulter des descriptions détaillées des opportunités professionnelles et bénévoles disponibles. L'objectif était de faciliter l'accès à l'information et d'améliorer l'engagement des candidats potentiels.</p>

                            <p>📰 Section "Presse" : Conception d'une section centralisée regroupant toutes les couvertures médiatiques de l'association, incluant interviews, articles, et reportages. Cette fonctionnalité offre une meilleure visibilité et une valorisation des actions de l'association.</p>

                            <p>✉️ Module d'abonnement newsletter: Intégration d'une fonctionnalité dans le pied de page permettant aux utilisateurs de s'inscrire facilement pour recevoir des actualités et des mises à jour régulières, améliorant ainsi l'engagement des membres. Mailchimp a été utilisé pour gérer les abonnements à la newsletter et automatiser les envois.</p>

                            <p>💻 Stack technique utilisée :

                                Outil principal : Hugo (choisi pour sa simplicité et ses facilités de modification)
                                Frontend : HTML5, CSS3
                                Backend : Génération statique via Hugo
                                Atout : Flexibilité pour des modifications rapides et adaptées aux besoins de l'association</p>
                        </div>
                    </div>
                </motion.div>
                <motion.div className="experience" initial="hidden"
                    animate={animateExperiences ? "visible" : "hidden"}
                    variants={experiencesVariants}
                    transition={{ duration: 1, delay: 0.5 }}>
                    <h2>Développeur décisionnaire</h2>
                    <div className="postInfo">
                        <div className="years">Septembre 2019 - Septembre 2020</div>
                        <div className="location">Aix-en-provence la duranne</div>
                        <div className="post-title">
                            <p>🎯 Alternance chez KEYRUS, Aix-en-Provence
                                📅 Diplôme : Bac +5 Chef de Projet Informatique</p>

                            <p>🚀 Formation Talend :
                                Acquisition approfondie de compétences sur Talend, un outil puissant d'intégration de données.
                                Développement d'expertises en manipulation et transformation de données.</p>

                            <p>✈️ Projet AMP (Aéroport Marseille Provence) :
                                Application pratique de Talend pour intégrer et transformer des données dans le cadre du projet de l'aéroport.
                                Participation active à la gestion de projet et à la collaboration inter-équipes dans un environnement dynamique.</p>

                            <p>Stack technique :
                                Intégration de données : Talend</p>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}